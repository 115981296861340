<template>
  <div>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("typeOfWork")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.work_type }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("unccBlastingLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.blasting ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("boringLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.boring ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    excavationDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped></style>
