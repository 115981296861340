var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("typeOfWork")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.work_type) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("unccBlastingLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.blasting ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("boringLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.boring ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }